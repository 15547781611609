import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
//import { applicationActions } from 'actions'

import { getApplication, postApplication, logout } from "actions";

import { validateEmail } from "helper";

import FileUpload from "components/page/parts/form/FileUpload";
import Input from "components/page/parts/form/Input";
import Radio from "components/page/parts/form/Radio";
import Checkbox from "components/page/parts/form/Checkbox";
import Textarea from "components/page/parts/form/Textarea";
import Repeater from "components/page/parts/form/Repeater";
import Datefield from "components/page/parts/form/Datefield";
import Select from "components/page/parts/form/Select";

import { costsLabels, revenueLabels, benefitsLabels } from "./FinanceLabels";
import FinanceRepeater from "components/page/parts/form/FinanceRepeater";

import styles from "./Application.module.sass";
import styles2 from "./ApplicationMain.module.sass";

const Application = ({
  applicationData,
  getApplication,
  postApplication,
  isPosting,
  location,
  authentication,
  logout,
  history,
  language,
}) => {
  const [data, setData] = useState(null);
  const [errors, setErrors] = useState([]);
  const [saved, setSaved] = useState(false);
  const [finished, setFinished] = useState(false);
  const [validated, setValidated] = useState(false);
  const [readOnly, setReadOnly] = useState(true);

  const [readyPublish, setReadyPublish] = useState(false);

  const project_date_start = new Date(2025, 7, 1);
  const project_date_end = new Date(2027, 4, 30);

  useEffect(() => {
    const query = new URLSearchParams(location.search);

    console.log(query.get("id"));

    if (query.get("id")) {
      getApplication(query.get("id"), query.get("key"));
    } else {
      getApplication();
      setReadOnly(false);
    }
  }, []);

  useEffect(() => {
    if (data && applicationData && applicationData.status === "publish") {
      setFinished(true);
    } else if (
      data &&
      applicationData &&
      applicationData.status !== "publish"
    ) {
      setSaved(true);
    }

    setData(applicationData);
  }, [applicationData]);

  useEffect(() => {
    //console.log(authentication)

    const query = new URLSearchParams(location.search);

    if (!authentication.isLoggedin && !query.get("id")) {
      if (language === "fr") {
        history.push("/fr/login/");
      } else {
        history.push("/login/");
      }
    }
  }, [authentication]);

  const onChange = (props) => {
    const newData = { ...data };
    newData[props.name] = props.value;

    setData(newData);
  };

  const onChangeFile = (props) => {
    const newData = { ...data };

    newData[props.type] = null;

    let files = newData["files"] ? newData["files"] : [];

    const index = files.findIndex((file) => file.type === props.type);

    if (index > -1) {
      files[index] = props;
    } else {
      files.push(props);
    }

    if (!props.base64) {
      files = files.filter((file) => file.type !== props.type);
    }

    newData["files"] = files;

    setData(newData);
    setTimeout(() => {
      if (!readOnly) {
        postApplication(newData);
      }
    }, 400);
  };

  const onChangeFinance = (type, i, col, value) => {
    const finance = data[type] ? [...data[type]] : [];
    if (!finance[i]) finance[i] = {};

    console.log(value);

    if (col === "col2" || col === "col3") {
      if (isNaN(value) || !value) {
        value = "";
      } else {
        //value = parseInt(value)
        value = parseFloat(value);
        //value = value.toFixed(2);
      }
    }

    finance[i][col] = value;

    setData({ ...data, [type]: finance });
  };

  const onBlurFinance = (type, i, col) => {
    const finance = data[type] ? [...data[type]] : [];
    if (!finance[i]) finance[i] = {};

    let value = finance[i][col];

    if (col === "col2" || col === "col3") {
      if (isNaN(value) || !value) {
        value = "";
      } else {
        //value = parseInt(value)
        value = parseFloat(value);
        value = value.toFixed(2);
      }
    }

    finance[i][col] = value;

    setData({ ...data, [type]: finance });
  };

  const onChangePress = (i, col, value) => {
    const pressData = data.press ? [...data.press] : [];
    if (!pressData[i]) pressData[i] = {};
    pressData[i][col] = value;

    setData({ ...data, press: pressData });
  };

  const handleValidate = () => {
    validateForm();
    setValidated(true);
  };

  const handleSave = (event) => {
    event.preventDefault();
    console.log("submit");

    postApplication(data);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("submit");

    if (readOnly) return;

    const newErrors = validateForm();

    if (newErrors.length) {
      handleValidate();
      return;
    }

    if (newErrors.length === 0) {
      postApplication({ ...data, noerror: true });
    }
  };

  const press = [
    { de: "Artikel Print national", fr: "Presse écrite nationale" },
    { de: "International", fr: "internationale" },
    { de: "Regional", fr: "régionale" },
    { de: "Radio" },
    { de: "TV" },
    { de: "Online", fr: "Web" },
    { de: "Andere", fr: "Autres (préciser)" },
  ];

  console.log(data);

  if (!data) return null;

  const costsTotal = data.costs.reduce(
    (acc, row) => {
      row.forEach((item) => {
        if (item.value) acc.col2 += parseFloat(item.value);
        if (item.value_2) acc.col3 += parseFloat(item.value_2);
      });

      console.log(row);

      return acc;
    },
    { col2: 0, col3: 0 }
  );

  const revenueTotal = data.revenue.reduce(
    (acc, row) => {
      row.forEach((item) => {
        if (item.value) acc.col2 += parseFloat(item.value);
        if (item.value_2) acc.col3 += parseFloat(item.value_2);
      });

      console.log(row);

      console.log(acc);

      return acc;
    },
    { col2: 0, col3: 0 }
  );

  costsTotal.col2 = parseFloat(costsTotal.col2);
  costsTotal.col3 = parseFloat(costsTotal.col3);

  revenueTotal.col2 = parseFloat(revenueTotal.col2);
  revenueTotal.col3 = parseFloat(revenueTotal.col3);

  console.log(typeof costsTotal.col2);
  console.log(typeof revenueTotal.col2);

  const validateForm = () => {
    let fields = [
      { name: "title" },
      { name: "project_promoters" },
      { name: "legal_form" },

      { name: "alreadySupported", type: "radio" },
      { name: "yearSupported", condition: data.alreadySupported === "yes" },
      { name: "type", type: "radio" },

      { name: "contact_street" },
      { name: "contact_postcode" },
      { name: "contact_city" },
      { name: "contact_country" },
      { name: "contact_phone" },
      { name: "contact_web" },

      { name: "legal_representative_name" },
      { name: "legal_representative_firstname" },
      { name: "legal_representative_position" },
      { name: "legal_representative_phone" },
      { name: "legal_representative_email", type: "email" },

      { name: "contact_person_name" },
      { name: "contact_person_firstname" },
      { name: "contact_person_position" },
      { name: "contact_person_phone" },
      { name: "contact_person_email", type: "email" },

      { name: "projektpartners", type: "repeater" },

      { name: "shortdescription_de", maxLength: 1000 },
      { name: "shortdescription_fr", maxLength: 1000 },

      { name: "premiere", type: "radio" },

      { name: "ensembles", type: "repeater" },

      { name: "goal", maxLength: 750 },
      { name: "goal_fr", maxLength: 750 },
      { name: "targetgroup" },
      { name: "targetgroup_fr" },

      { name: "exchange" },
      { name: "exchange_fr" },

      { name: "sustainability" },
      { name: "sustainability_fr" },

      { name: "project_date_start" },
      { name: "project_date_end" },
      { name: "dates_date_start" },
      //{ name: 'dates_date_end' },

      //{ name: 'description' },
      //{ name: 'description_fr' },

      { name: "vat", type: "radio" },

      { name: "dates", type: "repeater" },

      { name: "file1", type: "file" },

      { name: "document_location" },
      { name: "document_date" },
      { name: "file6", type: "file" },

      /* */

      { name: "requestedMusikFonds", type: "radio" },
    ];

    /*
        if (data.step === 2) {
            fields = [
                ...fields,
                { name: 'bank_iban' },
                { name: 'bank_bic' },
                { name: 'projectparticipants', type: 'repeater' },
                { name: 'audience', type: 'repeater' },

                { name: 'targetaudience', type: 'checkbox'},
            ]
        }
        */

    const newErrors = [];

    fields.forEach((field) => {
      console.log(data[field.name]);

      if (field.type === "file") {
        let files = data["files"] ? data["files"] : [];
        const index = files.findIndex((file) => file.type === field.name);

        if (!data[field.name] && index === -1) {
          newErrors.push({ name: field.name });
        }
      } else if (field.type === "email") {
        if (!validateEmail(data[field.name])) {
          newErrors.push({ name: field.name });
        }
      } else if (field.type === "checkbox" || field.type === "radio") {
        if (data[field.name] && data[field.name].length > 0) {
        } else {
          newErrors.push({ name: field.name });
        }
      } else if (field.type === "repeater") {
        console.log("check: " + field.name);

        let template;
        let error = [];

        switch (field.name) {
          case "projektpartners":
            template = [
              { name: "name" },
              { name: "contact" },
              { name: "country" },
              { name: "phone" },
              { name: "email", type: "email" },
            ];

            break;

          case "dates":
            template = [
              { name: "country" },
              { name: "city" },
              { name: "location" },
              { name: "date_start" },
              { name: "date_end" },
            ];

            break;

          case "projectparticipants":
            template = [
              { name: "firstname" },
              { name: "name" },
              { name: "email", type: "email" },
              { name: "ensemblename" },
              { name: "personcount" },
              { name: "nationality" },
              { name: "country" },
              { name: "date_start" },
              { name: "date_end" },
            ];

            break;

          case "audience":
            template = [
              { name: "city" },
              { name: "datescount" },
              { name: "hallcapacity" },
              { name: "workload" },
              { name: "ticketssold" },
            ];

            break;

          case "composers":
            template = [
              { name: "name" },
              { name: "firstname" },
              { name: "work" },
              { name: "country" },
            ];

            break;

          case "ensembles":
            template = [{ name: "name" }, { name: "country" }];

            break;
        }

        let value =
          data[field.name] && data[field.name].length ? data[field.name] : [{}];

        console.log("value: " + value);

        value.forEach((row, index) => {
          //console.log('check row:' + index)

          template.forEach((subField) => {
            if (subField.type === "email") {
              //console.log('subfield email check')

              if (!validateEmail(row[subField.name])) {
                if (!error[index]) error[index] = [];
                error[index].push({ name: subField.name });
              }
            } else if (!row[subField.name]) {
              if (!error[index]) error[index] = [];
              error[index].push({ name: subField.name });
            }
          });
        });

        if (error.length) {
          newErrors.push({ name: field.name, errors: error });
        }
      } else if (!data[field.name]) {
        console.log(field.name);
        console.log(field.condition);

        if (field.condition === undefined || field.condition === true) {
          newErrors.push({ name: field.name });
        }
      } else if (field.maxLength) {
        if (
          data[field.name].toString().split(" ").join("").length >
          field.maxLength
        ) {
          newErrors.push({ name: field.name });
        }
      }
    });

    console.log(newErrors);

    console.log(costsTotal);

    if (costsTotal.col2 === 0) {
      newErrors.push({ name: "costs" });
    }

    if (revenueTotal.col2 === 0) {
      newErrors.push({ name: "revenue" });
    }

    if (costsTotal.col2 && data.fundingImpuls) {
      //let revenueImpuls = data.revenue[9].col2;

      console.log(
        typeof parseFloat(data.fundingImpuls),
        typeof parseFloat(costsTotal.col2)
      );

      console.log(
        parseFloat(data.fundingImpuls),
        parseFloat(costsTotal.col2) / 2
      );

      if (parseFloat(data.fundingImpuls) > parseFloat(costsTotal.col2) / 2) {
        newErrors.push({ name: "fundingImpuls" });
      }
    }

    console.log("----------------------------------------");
    console.log(costsTotal.col2 !== revenueTotal.col2);
    console.log(revenueTotal.col2);
    console.log(data.fundingImpuls);
    console.log(costsTotal.col2);

    console.log(data.fundingImpuls > revenueTotal.col2 / 2);

    console.log(
      parseFloat(costsTotal.col2).toFixed(2),
      parseFloat(revenueTotal.col2).toFixed(2)
    );

    const compare1 = parseFloat(costsTotal.col2);
    const compare2 =
      parseFloat(data.fundingImpuls) + parseFloat(revenueTotal.col2);

    if (parseFloat(compare1).toFixed(2) !== parseFloat(compare2).toFixed(2)) {
      newErrors.push({ name: "costsRevenue" });
    }

    setErrors(newErrors);

    if (!newErrors.length) {
      setReadyPublish(true);
    } else {
      setReadyPublish(false);
    }

    return newErrors;
  };

  //console.log(costsTotal)

  let errorHtml = null;

  console.log(errors);
  //console.log(errors.length)

  if (errors.length !== 0) {
    const costsRevenueError =
      errors && errors.findIndex((error) => error.name === "costsRevenue") > -1;
    const fundingImpulsError =
      errors &&
      errors.findIndex((error) => error.name === "fundingImpuls") > -1;

    //alert(revenueImpulsError)

    if (costsRevenueError) {
      errorHtml = (
        <p>
          Es sind {errors.length} Fehler aufgetreten. Bitte Eingaben überprüfen.
          <br />
          Das Budget muss ausgeglichen sein, Einnahmen = Ausgaben.
          <br />
          <span className="fr">
            Le formulaire contient {errors.length} erreurs. Merci de vérifier
            les données. Le budget doit être équilibré, recettes = dépenses.
            <br />
          </span>
        </p>
      );
    } else if (fundingImpulsError) {
      errorHtml = (
        <p>
          Es sind {errors.length} Fehler aufgetreten. Bitte Eingaben überprüfen.
          <br />
          Die Antragssumme darf maximal 50% des Budgets betragen.
          <br />
          <span className="fr">
            Le formulaire contient {errors.length} erreurs. Merci de vérifier
            les données. La somme de soutien ne peut pas dépasser 50 % du
            budget.
            <br />
          </span>
        </p>
      );
    } else {
      errorHtml = (
        <p>
          Es sind {errors.length} Fehler aufgetreten. Bitte Eingaben überprüfen.
          <br />
          <span className="fr">
            Le formulaire contient {errors.length} erreurs. Merci de vérifier
            les données.
          </span>
        </p>
      );
    }

    /*
        errorHtml = <p>Es sind {errors.length} Fehler aufgetreten. Bitte Eingaben überprüfen.<br /><span className="fr">Le formulaire contient {errors.length} erreurs. Merci de vérifier les données.</span></p>
        */
  } else {
    errorHtml = (
      <p>
        Es sind keine Fehler aufgetreten.
        <br />
        <span className="fr">Aucune erreur ne s'est produite.</span>
      </p>
    );
  }

  let submitButtonClass =
    (data.status === "publish" && errors.length === 0) || readyPublish === true
      ? " validated"
      : "inactive";

  console.log(data);

  return (
    <div className={`${styles2.app} ${styles.application}`}>
      <div className="center main">
        <span className="logout" onClick={() => logout()}>
          logout
        </span>
        <form>
          <div>
            <h1>
              Antragsnummer /{" "}
              <span className="fr">Numéro du dossier de candidature</span>{" "}
              {data.category}/{data.applicationnumber}
            </h1>
            {data.projectnumber && (
              <h1>
                Projektnummer / <span className="fr">Numéro du projet</span>{" "}
                {data.category}/{data.projectnumber}
              </h1>
            )}
            <p style={{ marginTop: "15px", marginBottom: "15px" }}>
              Bevor Sie den Förderantrag ausfüllen, überprüfen Sie bitte auf
              www.impulsneuemusik.com, ob lhr Projekt den Kriterien des Vereins
              entspricht. Der Förderantrag muss in jeder Kategorie unbedingt auf
              Deutsch und Französisch ausgefüllt werden.
              {data.step === 2 && (
                <>
                  <span> </span>Der Fördervertrag basiert auf den Informationen
                  in diesem Formular. Etwaige Änderungen nach
                  Vertragsunterschrift müssen schriftlich bei Impuls neue Musik
                  angezeigt werden.
                </>
              )}
              <span className="fr">
                {" "}
                Avant de remplir le dossier de candidature, veuillez vérifier
                sur notre site internet www.impulsneuemusik.com que votre projet
                réponde aux critères de l’association. Chaque catégorie du
                dossier de candidature doit impérativement être remplie en
                français et en allemand.
                {data.step === 2 && (
                  <>
                    <span> </span>Le contrat fixant les modalités de versement
                    de l’aide se base sur les informations remplies dans ce
                    formulaire. En cas de modification de ces informations après
                    signature du contrat, merci d’en informer immédiatement
                    Impuls neue Musik.
                  </>
                )}
              </span>
            </p>

            <h2 className="tall">
              1. Projektträger:in
              <br />
              <span className="fr">1. Porteur.se de projet</span>
            </h2>

            <Input
              labels={{ de: "Projektname", fr: "Nom du projet" }}
              name="title"
              value={data.title}
              onChange={(props) => onChange(props)}
              errors={errors}
            />
            <Input
              labels={{ de: "Projektträger:in", fr: "Porteur.se de projet" }}
              name="project_promoters"
              value={data.project_promoters}
              onChange={(props) => onChange(props)}
              errors={errors}
            />
            <h2>
              Art des Projektträgers (bitte ankreuzen)
              <br />
              <span className="fr">
                Type de porteur de projet (merci de cocher la case
                correspondante)
              </span>
            </h2>
            <Radio
              data={[
                {
                  value: "EinzelkünstlerIn",
                  labels: { de: "Einzelkünstler:in", fr: "Artiste" },
                },
                {
                  value: "Ensemble",
                  labels: { de: "Ensemble", fr: "Ensemble" },
                },
                {
                  value: "Spielstätte",
                  labels: { de: "Spielstätte", fr: "Lieux de production" },
                },
                {
                  value: "Festival",
                  labels: { de: "Festival", fr: "Festival" },
                },
                {
                  value: "Akademie",
                  labels: { de: "Akademie", fr: "Académie" },
                },
                { value: "Andere", labels: { de: "Andere", fr: "Autre" } },
              ]}
              name="type"
              value={data.type}
              onChange={(props) => onChange(props)}
              errors={errors}
            />
            <Input
              labels={{ de: "Rechtsform", fr: "Forme juridique" }}
              name="legal_form"
              value={data.legal_form}
              onChange={(props) => onChange(props)}
              errors={errors}
            />
            <h2>
              Wurden Sie schon einmal durch Impuls neue Musik unterstützt?
              <br />
              <span className="fr">
                Avez-vous déjà été soutenu par Impuls neue Musik?
              </span>
            </h2>
            <Radio
              data={[
                { value: "yes", labels: { de: "ja", fr: "oui" } },
                { value: "no", labels: { de: "nein", fr: "non" } },
              ]}
              name="alreadySupported"
              value={data.alreadySupported}
              onChange={(props) => onChange(props)}
              errors={errors}
            />
            {data.alreadySupported === "yes" && (
              <Input
                labels={{ de: "Jahr", fr: "Année" }}
                name="yearSupported"
                value={data.yearSupported}
                onChange={(props) => onChange(props)}
                errors={errors}
                addClasses={["small"]}
              />
            )}
            <h2>
              Kontaktdaten
              <br />
              <span className="fr">Coordonnées</span>
            </h2>
            <div className="cols3">
              <div>
                <Input
                  labels={{ de: "Adresse", fr: "Adresse" }}
                  name="contact_street"
                  value={data.contact_street}
                  onChange={(props) => onChange(props)}
                  errors={errors}
                />
              </div>
              <div>
                <Input
                  labels={{ de: "PLZ", fr: "Code postal" }}
                  name="contact_postcode"
                  value={data.contact_postcode}
                  onChange={(props) => onChange(props)}
                  errors={errors}
                />
              </div>
              <div>
                <Input
                  labels={{ de: "Stadt", fr: "Ville" }}
                  name="contact_city"
                  value={data.contact_city}
                  onChange={(props) => onChange(props)}
                  errors={errors}
                />
              </div>
              <div>
                <Select
                  labels={{ de: "Land", fr: "Pays" }}
                  name="contact_country"
                  value={data.contact_country}
                  options={[
                    {
                      value: "Deutschland",
                      labels: { de: "Deutschland", fr: "Allemagne" },
                    },
                    {
                      value: "Schweiz",
                      labels: { de: "Schweiz", fr: "Suisse" },
                    },
                    {
                      value: "Frankreich",
                      labels: { de: "Frankreich", fr: "France" },
                    },
                    {
                      value: "Luxemburg",
                      labels: { de: "Luxemburg", fr: "Luxembourg" },
                    },
                  ]}
                  onChange={(props) => onChange(props)}
                  errors={errors}
                  custom={false}
                />
              </div>
              <div>
                <Input
                  labels={{ de: "Tel. Nr.", fr: "N° de téléphone" }}
                  name="contact_phone"
                  value={data.contact_phone}
                  onChange={(props) => onChange(props)}
                  errors={errors}
                  addClasses={["telephone"]}
                  type="number"
                />
              </div>
              <div>
                <Input
                  labels={{ de: "Fax ", fr: "Fax " }}
                  name="contact_fax"
                  value={data.contact_fax}
                  onChange={(props) => onChange(props)}
                  errors={errors}
                />
              </div>
              <div>
                <Input
                  labels={{ de: "Website ", fr: "Site internet" }}
                  name="contact_web"
                  value={data.contact_web}
                  onChange={(props) => onChange(props)}
                  errors={errors}
                />
              </div>
            </div>
            <h2>
              Juristische.r Vertreter:in der Institution (unterschreibt den
              Vertrag)
              <br />
              <span className="fr">
                Représentant.e légal.e de la structure (signataire du contrat)
              </span>
            </h2>
            <div className="cols3">
              <div>
                <Input
                  labels={{ de: "Name", fr: "Nom" }}
                  name="legal_representative_name"
                  value={data.legal_representative_name}
                  onChange={(props) => onChange(props)}
                  errors={errors}
                />
              </div>
              <div>
                <Input
                  labels={{ de: "Vorname", fr: "Prénom" }}
                  name="legal_representative_firstname"
                  value={data.legal_representative_firstname}
                  onChange={(props) => onChange(props)}
                  errors={errors}
                />
              </div>
              <div>
                <Input
                  labels={{ de: "Position", fr: "Fonction" }}
                  name="legal_representative_position"
                  value={data.legal_representative_position}
                  onChange={(props) => onChange(props)}
                  errors={errors}
                />
              </div>
              <div>
                <Input
                  labels={{ de: "Tel. Nr.", fr: "N° de téléphone" }}
                  name="legal_representative_phone"
                  value={data.legal_representative_phone}
                  onChange={(props) => onChange(props)}
                  errors={errors}
                  addClasses={["telephone"]}
                  type="number"
                />
              </div>
              <div>
                <Input
                  labels={{ de: "E-Mail" }}
                  name="legal_representative_email"
                  value={data.legal_representative_email}
                  onChange={(props) => onChange(props)}
                  errors={errors}
                />
              </div>
            </div>
            <h2>
              Ansprechpartner:in
              <br />
              <span className="fr">Responsable du suivi administratif</span>
            </h2>
            <div className="cols3">
              <div>
                <Input
                  labels={{ de: "Name", fr: "Nom" }}
                  name="contact_person_name"
                  value={data.contact_person_name}
                  onChange={(props) => onChange(props)}
                  errors={errors}
                />
              </div>
              <div>
                <Input
                  labels={{ de: "Vorname", fr: "Prénom" }}
                  name="contact_person_firstname"
                  value={data.contact_person_firstname}
                  onChange={(props) => onChange(props)}
                  errors={errors}
                />
              </div>
              <div>
                <Input
                  labels={{ de: "Position", fr: "Fonction" }}
                  name="contact_person_position"
                  value={data.contact_person_position}
                  onChange={(props) => onChange(props)}
                  errors={errors}
                />
              </div>
              <div>
                <Input
                  labels={{ de: "Tel. Nr.", fr: "N° de téléphone" }}
                  name="contact_person_phone"
                  value={data.contact_person_phone}
                  onChange={(props) => onChange(props)}
                  errors={errors}
                  addClasses={["telephone"]}
                  type="number"
                />
              </div>
              <div>
                <Input
                  labels={{ de: "E-Mail" }}
                  name="contact_person_email"
                  value={data.contact_person_email}
                  onChange={(props) => onChange(props)}
                  errors={errors}
                />
              </div>
            </div>
            {data.step === 2 && (
              <>
                <h2>
                  Bankverbindung des Vertragpartners
                  <br />
                  <span className="fr">
                    Coordonnée bancaires et juridiques du co-contractant
                  </span>
                </h2>
                <div className="cols3">
                  <div>
                    <Input
                      labels={{ de: "IBAN", fr: "IBAN" }}
                      name="bank_iban"
                      value={data.bank_iban}
                      onChange={(props) => onChange(props)}
                      errors={errors}
                    />
                  </div>
                  <div>
                    <Input
                      labels={{ de: "BIC", fr: "BIC" }}
                      name="bank_bic"
                      value={data.bank_bic}
                      onChange={(props) => onChange(props)}
                      errors={errors}
                    />
                  </div>
                  <div>
                    <Input
                      labels={{ de: "Name der Bank", fr: "Nom de la banque" }}
                      name="bank_name"
                      value={data.bank_name}
                      onChange={(props) => onChange(props)}
                      errors={errors}
                    />
                  </div>
                  <div>
                    <Input
                      labels={{
                        de: "Adresse der Bank",
                        fr: "Adresse de la banque",
                      }}
                      name="bank_address"
                      value={data.bank_address}
                      onChange={(props) => onChange(props)}
                      errors={errors}
                    />
                  </div>
                </div>
              </>
            )}
            <h2>
              Weitere Projektpartner (Ensembles, Co-Produzenten, etc., bitte
              hier keine Komponist:innen eintragen)
              <br />
              <span className="fr">
                Autres partenaires du projet (ensembles, co-producteur.trices,
                etc., aucun.e compositeur.trice ne doit être inscrit ici)
              </span>
            </h2>
            <Repeater
              name="projektpartners"
              value={data.projektpartners}
              onChange={(props) => onChange(props)}
              labels={{
                de: "Weitere Projektpartner Hinzufügen",
                fr: "Ajouter d’autres partenaires du projet",
              }}
              errors={errors}
              removeLabels={{
                de: "Diesen Projektpartner entfernen",
                fr: "Supprimer ce partenaire",
              }}
              isLoggedin={authentication.isLoggedin}
            />
            <h2 className="tall">
              2. Projektangaben
              <br />
              <span className="fr">2. Descriptif du projet</span>
            </h2>
            <Textarea
              labels={{
                de: "Kurzbeschreibung des Projekts auf Deutsch (max. 1000 Zeichen)",
              }}
              name="shortdescription_de"
              value={data.shortdescription_de}
              onChange={(props) => onChange(props)}
              errors={errors}
              maxLength={1000}
            />
            <Textarea
              labels={{
                fr: "Bref descriptif du projet en français (max. 1000 caractères)",
              }}
              name="shortdescription_fr"
              value={data.shortdescription_fr}
              onChange={(props) => onChange(props)}
              errors={errors}
              maxLength={1000}
            />
            <Textarea
              labels={{ de: "Projektziele (max. 750 Zeichen)" }}
              name="goal"
              value={data.goal}
              onChange={(props) => onChange(props)}
              errors={errors}
              maxLength={750}
            />

            <Textarea
              labels={{ fr: "Objectifs du projet (max. 750 caractères)" }}
              name="goal_fr"
              value={data.goal_fr}
              onChange={(props) => onChange(props)}
              errors={errors}
              maxLength={750}
            />

            <Textarea
              labels={{
                de: "Welche Personengruppen will Ihr Projekt ansprechen?",
              }}
              name="targetgroup"
              value={data.targetgroup}
              onChange={(props) => onChange(props)}
              errors={errors}
            />

            <Textarea
              labels={{ fr: "À quel type de public s’adresse votre projet ?" }}
              name="targetgroup_fr"
              value={data.targetgroup_fr}
              onChange={(props) => onChange(props)}
              errors={errors}
            />

            <p style={{ marginTop: "20px", marginBottom: "14px" }}>
              Austausch zwischen dem französischsprachigen und dem
              deutschsprachigen Raum
              <br />
              <br />
              Auf welcher Ebene zeigt sich der Austausch zwischen dem
              französischsprachigen und dem deutschsprachigen Raum in Ihrem
              Projekt? Erläutern Sie dies in ein paar Sätzen (max. 1000 Zeichen)
              und nennen Sie konkrete Beispiele, wie etwa Kooperationen zwischen
              Künstler:innen, Ensembles, Koproduktionen, Workshops, Konzerte
              oder Residenzen, die mindestens 2 der 4 Ländern (Deutschland,
              Frankreich, Schweiz, Luxemburg) einbinden. Mehr Informationen
              finden Sie in den FAQs.
            </p>

            <Textarea
              name="exchange"
              value={data.exchange}
              onChange={(props) => onChange(props)}
              errors={errors}
              maxLength={1000}
            />

            <p style={{ marginTop: "20px", marginBottom: "14px" }}>
              <strong>
                Échange franco-germanophone
                <br />
                <br />À quel niveau se manifeste l'échange franco-germanophone
                dans votre projet ? Expliquez-le en quelques lignes (maximum
                1000 caractères) en mettant en avant des exemples concrets, tels
                que des collaborations entre artistes, ensembles, des
                coproductions, des ateliers, des concerts ou des résidences
                impliquant minimum deux des quatre pays (Allemagne, France,
                Suisse, Luxembourg). Pour plus d’informations, veuillez
                consulter la foire aux questions.
              </strong>
            </p>

            <Textarea
              name="exchange_fr"
              value={data.exchange_fr}
              onChange={(props) => onChange(props)}
              errors={errors}
              maxLength={1000}
            />

            <p style={{ marginTop: "20px", marginBottom: "14px" }}>
              Nachhaltigkeit
              <br />
              <br />
              Impuls neue Musik setzt sich für einen umweltbewussten,
              ressourcenschonenden Einsatz seiner Fördermittel ein. Die
              Projektträger:innen sollten dieses Ziel bei Planung, Durchführung
              und Nachbereitung des Projekts im Rahmen der geltenden
              Rechtsvorschriften berücksichtigen (siehe Förderkriterien). Bitte
              beschreiben Sie inwieweit Sie in Ihrem Projekt das Thema
              Nachhaltigkeit berücksichtigen.
            </p>

            <Textarea
              name="sustainability"
              value={data.sustainability}
              onChange={(props) => onChange(props)}
              errors={errors}
            />

            <p style={{ marginTop: "20px", marginBottom: "14px" }}>
              <strong>
                Développement durable
                <br />
                <br />
                Impuls neue Musik s’engage à utiliser ses fonds de manière
                écologique et en économisant les ressources. Les porteur.euses
                de projet doivent tenir compte de cet objectif dans la
                planification, la mise en oeuvre et le suivi de leur projet dans
                le cadre des dispositions juridiques applicables (voir critères
                de soutien). Veuillez indiquer de quelle manière votre projet
                s'inscrit dans une démarche de développement durable.{" "}
              </strong>
            </p>

            <Textarea
              name="sustainability_fr"
              value={data.sustainability_fr}
              onChange={(props) => onChange(props)}
              errors={errors}
            />

            <h2>
              Beteiligte Komponist:innen
              <br />
              <span className="fr">Compositeur.trices impliqué.es</span>
            </h2>
            <Repeater
              name="composers"
              value={data.composers}
              onChange={(props) => onChange(props)}
              labels={{ de: "Komponist*in", fr: "Compositeur.rice" }}
              errors={errors}
              removeLabels={{ de: "Entfernen", fr: "Supprimer" }}
              isLoggedin={authentication.isLoggedin}
            />

            <div style={{ display: "flex", marginTop: "30px" }}>
              <label style={{ lineHeight: "44px", margin: "0 15px 0 0" }}>
                Uraufführung(en) <span className="fr">Création(s):</span>
              </label>
              <Radio
                data={[
                  { value: "yes", labels: { de: "ja", fr: "oui" } },
                  { value: "no", labels: { de: "nein", fr: "non" } },
                ]}
                name="premiere"
                value={data.premiere}
                onChange={(props) => onChange(props)}
                errors={errors}
              />
            </div>

            <h2>
              Beteiligte Ensembles
              <br />
              <span className="fr">Ensembles participants au projet</span>
            </h2>

            <Repeater
              name="ensembles"
              value={data.ensembles}
              onChange={(props) => onChange(props)}
              labels={{ de: "Ensemble", fr: "" }}
              errors={errors}
              removeLabels={{ de: "Entfernen", fr: "Supprimer" }}
              isLoggedin={authentication.isLoggedin}
            />

            <h2>
              Projektlaufzeit
              <br />
              <span className="fr">Période de réalisation du projet</span>
            </h2>
            <p>
              Die Projektlaufzeit erstreckt sich von der Umsetzung (Workshop,
              Proben...) bis zur Abrechnung. Die Projekte dürfen frühestens am
              1. August 2025 beginnen und spätestens am 30. Mai 2027 enden.
              <br />
              <span className="fr">
                La période de réalisation du projet s'étend de sa mise en place
                (workshops, répétitions...) au bilan. Les projets retenus
                doivent débuter au plus tôt le 1er août 2025 et se terminer au
                plus tard au 30 mai 2027. Un report du projet n’est pas
                autorisé.
              </span>
            </p>
            <div className="cols3">
              <div>
                <Datefield
                  labels={{ de: "Anfangsdatum", fr: "Date de début" }}
                  name="project_date_start"
                  value={data.project_date_start}
                  onChange={(props) => onChange(props)}
                  errors={errors}
                  includeDateIntervals={[
                    { start: project_date_start, end: project_date_end },
                  ]}
                  openToDate={project_date_start}
                />
              </div>
              <div>
                <Datefield
                  labels={{ de: "Enddatum", fr: "Date de fin" }}
                  name="project_date_end"
                  value={data.project_date_end}
                  onChange={(props) => onChange(props)}
                  errors={errors}
                  includeDateIntervals={[
                    { start: project_date_start, end: project_date_end },
                  ]}
                  openToDate={project_date_start}
                />
              </div>
            </div>
            <h2>
              Veranstaltungstermine
              <br />
              <span className="fr">Dates de la / des manifestation(s)</span>
            </h2>
            <div className="cols3">
              <div>
                <Datefield
                  labels={{ de: "Anfangsdatum", fr: "Date de début" }}
                  name="dates_date_start"
                  value={data.dates_date_start}
                  onChange={(props) => onChange(props)}
                  errors={errors}
                  includeDateIntervals={[
                    { start: project_date_start, end: project_date_end },
                  ]}
                  openToDate={project_date_start}
                />
              </div>
              <div>
                <Datefield
                  labels={{ de: "Enddatum", fr: "Date de fin" }}
                  name="dates_date_end"
                  value={data.dates_date_end}
                  onChange={(props) => onChange(props)}
                  errors={errors}
                  includeDateIntervals={[
                    { start: project_date_start, end: project_date_end },
                  ]}
                  openToDate={project_date_start}
                />
              </div>
            </div>
            <h2>
              Detaillierte Projektplanung (Termine und Orte)
              <br />
              <span className="fr">
                Planning detaillé du projet (date(s) et lieu(x))
              </span>
            </h2>
            <Repeater
              name="dates"
              value={data.dates}
              onChange={(props) => onChange(props)}
              labels={{
                de: "Weiteren Termin  hinzufügen",
                fr: "Ajouter plus de dates",
              }}
              errors={errors}
              removeLabels={{
                de: "Diesen Termin entfernen",
                fr: "Supprimer cette date",
              }}
              isLoggedin={authentication.isLoggedin}
              includeDateIntervals={[
                { start: project_date_start, end: project_date_end },
              ]}
              openToDate={project_date_start}
            />
            {data.step === 2 && (
              <>
                <h2>
                  Projektbeteiligte
                  <br />
                  <span className="fr">Liste des participants au projet</span>
                </h2>
                <p>
                  Hier müssen alle Projektbeteiligte augeführt werden, von denen
                  es voraussichtlich Rechnungen ode Belege von Ausgaben geben
                  wird. Bei mehr als 30 Namen, bitte extra Liste beifügen
                  (unterschrieben und mit Stempel).{" "}
                  <span className="fr">
                    Ces listes doivent recenser l’intégralité des personnes
                    participant au projet et dont l’intervention est susceptible
                    de générer des frais sur lesquels portera l’aide. Si le
                    nombre de participants est supérieur à 30 personnes, merci
                    de joindre une liste au présent document (datée, signée et
                    avec tampon de la structure).
                  </span>
                </p>
                <Repeater
                  name="projectparticipants"
                  value={data.projectparticipants}
                  onChange={(props) => onChange(props)}
                  labels={{
                    de: "Weitere Projektbeteiligte hinzufügen",
                    fr: "Ajouter d’autres participants du projet",
                  }}
                  errors={errors}
                  removeLabels={{
                    de: "Diesen Projektbeteiligten entfernen",
                    fr: "Supprimer ce participant au projet",
                  }}
                  isLoggedin={authentication.isLoggedin}
                />
              </>
            )}

            <h2 className="tall">
              3. Finanzierungsplan
              <br />
              <span className="fr">3. Budget prévisionnel</span>
            </h2>

            <p style={{ marginTop: "25px", marginBottom: "10px" }}>
              Ist die antragstellende Institution vorsteuerabzugsberechtigt?{" "}
              <span className="fr">
                La structure co-contractante est-elle assujettie à la TVA?
              </span>
            </p>
            <Radio
              data={[
                {
                  value: "ja",
                  labels: {
                    de: "Ja (Nettobeträge angeben)",
                    fr: "Oui (budget Hors-taxes)",
                  },
                },
                {
                  value: "nein",
                  labels: {
                    de: "Nein (Bruttobeträge angeben)",
                    fr: "Non (budget toutes taxes comprises)",
                  },
                },
              ]}
              name="vat"
              value={data.vat}
              onChange={(props) => onChange(props)}
              errors={errors}
            />

            <p>
              Der Finanzierungsplan muss ausgeglichen sein (Ausgaben=Einnahmen).
              Alle Posten müssen in Euro angegeben sein.
              <br />
              <span className="fr">
                Le budget doit être équilibré (dépenses=recettes). Tous les
                postes de dépenses et de recettes doivent être indiqués en
                euros.
              </span>
            </p>

            <p style={{ marginTop: "20px" }}>
              Dieser Finanzierungsplan muss ausgefüllt werden, um den Antrag
              einzureichen. Bitte keine extra Datei mitschicken. Bitte
              schlüsseln Sie hier die Einzelposten auf, z.B. Anzahl / Höhe von
              Einzelsummen, sodass die Höhe der Gesamtsumme nachvollzogen werden
              kann.
              <br />
              <span className="fr">
                Le budget prévisionnel doit être complété directement dans le
                formulaire. Aucun document complémentaire ne doit être envoyé.
                Merci de préciser les différents postes de dépenses en indiquant
                pour chacun d’entre eux les montants permettant de calculer la
                somme totale.
              </span>
            </p>

            <p style={{ marginTop: "20px" }}>
              Ein Monat nach Ende der Projektlaufzeit muss ein
              Verwendungsnachweis inkl. aller Unterlagen bei Impuls neue Musik
              eingegangen sein. Der Verwendungsnachweis besteht aus einem
              zahlenmäßigen Nachweis der Ausgaben und Einnahmen sowie aus einem
              Sachbericht.
              <br />
              <span className="fr">
                Un mois après la fin de la période de réalisation du projet, un
                mémoire de frais ainsi qu'un rapport final, devront être envoyés
                à Impuls neue Musik.
              </span>
            </p>

            <h2>
              AUSGABEN
              <br />
              <span className="fr">DÉPENSES</span>
            </h2>

            <div className="finance">
              {costsLabels.map((row, i) => {
                const costsEerror =
                  errors &&
                  errors.findIndex((error) => error.name === "costs") > -1;

                return (
                  <div key={i}>
                    {row.title && (
                      <h3 dangerouslySetInnerHTML={{ __html: row.title }}></h3>
                    )}
                    <FinanceRepeater
                      name="costs"
                      value={data.costs[i]}
                      onChange={(props) => {
                        const newData = { ...data };
                        newData.costs[i] = props.value;

                        console.log(newData);

                        setData(newData);
                      }}
                      labels={{
                        de: "Zeile hinzufügen",
                        fr: "ajouter une rangée",
                      }}
                      errors={errors}
                      removeLabels={{ de: "X", fr: "" }}
                      isLoggedin={authentication.isLoggedin}
                      placeHolders={row.placeHolders ? row.placeHolders : []}
                    />
                  </div>
                );
              })}
            </div>

            <h2>
              EINNAHMEN
              <br />
              <span className="fr">RECETTES</span>
            </h2>

            <div className="finance revenue">
              {revenueLabels.map((row, i) => {
                const costsEerror =
                  errors &&
                  errors.findIndex((error) => error.name === "costs") > -1;

                return (
                  <div key={i}>
                    {row.title && (
                      <h3 dangerouslySetInnerHTML={{ __html: row.title }}></h3>
                    )}
                    <FinanceRepeater
                      name="costs"
                      value={data.revenue[i]}
                      onChange={(props) => {
                        const newData = { ...data };
                        newData.revenue[i] = props.value;

                        console.log(newData);

                        setData(newData);
                      }}
                      labels={{
                        de: "Zeile hinzufügen",
                        fr: "ajouter une rangée",
                      }}
                      errors={errors}
                      removeLabels={{ de: "X", fr: "" }}
                      isLoggedin={authentication.isLoggedin}
                      placeHolders={row.placeHolders ? row.placeHolders : []}
                    />
                  </div>
                );
              })}
            </div>

            <h2>
              GESAMTSUMMEN
              <br />
              <span className="fr">SOMMES TOTALES</span>
            </h2>

            <div className="finance">
              <div className="row row1">
                <div className="cols">
                  <div>
                    <div className="text-fixed">
                      Gesamtsumme Ausgaben{" "}
                      <span className="fr">Total des dépenses</span>
                    </div>
                  </div>
                  <div>
                    <Input
                      name="revenue"
                      value={
                        costsTotal.col2 > 0
                          ? parseFloat(costsTotal.col2).toFixed(2)
                          : ""
                      }
                      addClasses={["currency"]}
                      addText="€"
                      type="currency"
                      readonly
                    />
                  </div>
                  <div></div>
                </div>
              </div>

              <div className="row row1">
                <div className="cols">
                  <div>
                    <div className="text-fixed">
                      Gesamtsumme Einnahmen{" "}
                      <span className="fr">Total des recettes</span>
                    </div>
                  </div>
                  <div>
                    <Input
                      name="revenue"
                      value={
                        revenueTotal.col2 > 0
                          ? parseFloat(revenueTotal.col2).toFixed(2)
                          : ""
                      }
                      addClasses={["currency"]}
                      addText="€"
                      type="currency"
                      readonly
                    />
                  </div>
                  <div></div>
                </div>
              </div>
              <div className="row row1">
                <div className="cols">
                  <div>
                    <div className="text-fixed">
                      Beantragte Fördersumme bei Impuls neue Musik, max. 50% der
                      Gesamtausgaben{" "}
                      <span className="fr">
                        Montant de la subvention demandée auprès d'Impuls neue
                        Musik. La somme ne doit pas dépasser 50% des dépenses
                      </span>
                    </div>
                  </div>
                  <div>
                    <Input
                      name="fundingImpuls"
                      value={data.fundingImpuls}
                      onChange={(props) => onChange(props)}
                      errors={errors}
                      addClasses={["currency"]}
                      addText="€"
                      type="currency"
                    />
                  </div>
                  <div></div>
                </div>
              </div>
            </div>

            <div className="finance">
              {benefitsLabels.map((row, i) => {
                const costsEerror =
                  errors &&
                  errors.findIndex((error) => error.name === "costs") > -1;

                return (
                  <div key={i}>
                    {row.title && (
                      <h3 dangerouslySetInnerHTML={{ __html: row.title }}></h3>
                    )}
                    <FinanceRepeater
                      name="costs"
                      value={data.benefits[i]}
                      onChange={(props) => {
                        const newData = { ...data };
                        newData.benefits[i] = props.value;

                        console.log(newData);

                        setData(newData);
                      }}
                      labels={{
                        de: "Zeile hinzufügen",
                        fr: "ajouter une rangée",
                      }}
                      errors={errors}
                      removeLabels={{ de: "X", fr: "" }}
                      isLoggedin={authentication.isLoggedin}
                      placeHolders={row.placeHolders ? row.placeHolders : []}
                    />
                  </div>
                );
              })}
            </div>

            <h2>
              Haben Sie dieses Projekt auch beim Musikfonds e.V. beantragt?
              <br />
              <span className="fr">
                Avez-vous fait une demande de subventions auprès du Musikfonds
                e.V. ?
              </span>
            </h2>
            <Radio
              data={[
                { value: "ja", labels: { de: "Ja", fr: "Oui" } },
                { value: "nein", labels: { de: "Nein", fr: "Non" } },
              ]}
              name="requestedMusikFonds"
              value={data.requestedMusikFonds}
              onChange={(props) => onChange(props)}
              errors={errors}
            />

            {data.step === 2 && (
              <>
                <h2>
                  Projektbilanz
                  <br />
                  <span className="fr">Bilan du projet</span>
                </h2>
                <p>
                  Die Projektbilanz ist Voraussetzung für die Auszahlung der
                  (restlichen) Fördersumme.{" "}
                  <span className="fr">
                    L’évaluation du projet est indispensable pour le versement
                    du solde de la subvention.
                  </span>
                </p>
                <h2>
                  Allgemeine Beurteilung des Projekts
                  <br />
                  <span className="fr">Appréciation generale</span>
                </h2>
                <Textarea
                  labels={{
                    de: "Wie würden Sie Ihr Projekt beschreiben? War es ein Erfolg? Haben Sie Ihre Ziele erreicht? Wo lagen die Schwierigkeiten?",
                    fr: "Comment décririez-vous votre projet? Etait-ce une réussite? Où se trouvaient les difficultés?",
                  }}
                  name="success"
                  value={data.success}
                  onChange={(props) => onChange(props)}
                />
                <Textarea
                  labels={{
                    de: "Wird das Projekt wiederaufgenommen? Wenn ja, in welchem Rahmen ?",
                    fr: "Votre projet va-t-il être repris ? Si oui, dans quel cadre ?",
                  }}
                  name="repeat"
                  value={data.repeat}
                  onChange={(props) => onChange(props)}
                />
                <Textarea
                  labels={{
                    de: "Zur deutsch-französisch-schweizerischen Zusammenarbeit: Wie gut war die Zusammenarbeit / der Austausch mit den Partnerländern?",
                    fr: "Coopération franco-germano-suisse: Comment s’est déroulé(e) cet échange / cette coopération avec les pays partenaires?",
                  }}
                  name="collaboration"
                  value={data.collaboration}
                  onChange={(props) => onChange(props)}
                />
                <Textarea
                  labels={{
                    de: "Planen Sie weitere gemeinsame Veranstaltungen? Wenn ja wo und wann?",
                    fr: "Prévoyez-vous d’autres manifestations en partenariat? Si oui, où et quand auront-elles lieu?",
                  }}
                  name="moreEvents"
                  value={data.moreEvents}
                  onChange={(props) => onChange(props)}
                />
                <Textarea
                  labels={{
                    de: "Bei Auftragswerken: Ist eine Wiederaufnahme des/der geförderten Auftragswerk(e)s geplant? Wenn ja, wo,und wann?",
                    fr: "Si votre projet présente une ou plusieurs commande(s): d’autres représentations sont-elles prévues ? Si oui, où et quand auront-elles lieu?",
                  }}
                  name="resumptionWorks"
                  value={data.resumptionWorks}
                  onChange={(props) => onChange(props)}
                />
                <h2>
                  Publikum
                  <br />
                  <span className="fr">Audience</span>
                </h2>
                <Repeater
                  name="audience"
                  value={data.audience}
                  onChange={(props) => onChange(props)}
                  labels={{
                    de: "Weiteren Ort hinzufügen",
                    fr: "Ajouter d’autres lieux",
                  }}
                  errors={errors}
                  removeLabels={{
                    de: "Diesen Ort entfernen",
                    fr: "supprimer ce lieu",
                  }}
                  isLoggedin={authentication.isLoggedin}
                />
                <h2>
                  Zielpublikum <span className="fr">Public cible</span>
                </h2>
                <Checkbox
                  data={[
                    {
                      value: "Kinder",
                      labels: { de: "Kinder", fr: "enfants" },
                    },
                    {
                      value: "Jugendliche",
                      labels: { de: "Jugendliche", fr: "Adolescents" },
                    },
                    {
                      value: "Studierende",
                      labels: { de: "Studierende", fr: "Étudiants" },
                    },
                    {
                      value: "Erwachsene",
                      labels: { de: "Erwachsene", fr: "Adultes" },
                    },
                    {
                      value: "Alle Zielgruppen",
                      labels: { de: "Alle Zielgruppen", fr: "Tous publics" },
                    },
                    {
                      value: "Breite Öffentlichkeit",
                      labels: {
                        de: "Breite Öffentlichkeit",
                        fr: "Grand public",
                      },
                    },
                    {
                      value: "Fachleute",
                      labels: { de: "Fachleute", fr: "Professionnels" },
                    },
                  ]}
                  name="targetaudience"
                  value={data.targetaudience}
                  onChange={(props) => onChange(props)}
                  errors={errors}
                />
                <h2>
                  Presse- und Öffentlichkeitsarbeit, Berichterstattung
                  <br />
                  <span className="fr">
                    Communication et diffusion, couverture médiatique
                  </span>
                </h2>
                <p>
                  Bitte fügen Sie einen Pressespiegel mit wichtigen Artikeln
                  über Ihr Projekt und ggfs. Radio-, Fernseh- oder
                  Internetbeiträge bei sowie Belege Ihrer Werbematerialien (am
                  besten elektronisch){" "}
                  <span className="fr">
                    Merci de joindre une revue de presse (en version
                    électronique) contenant des articles de journaux (papier et
                    web), des passages à la radio ou à la télévision, ainsi que
                    les supports de communication en lien avec votre projet.
                  </span>
                </p>
                <div className="press">
                  <div className="cols">
                    <div></div>
                    <div>
                      <label>
                        Anzahl <span className="fr">Nombre d’apparitions</span>
                      </label>
                    </div>
                    <div>
                      <label>
                        Zeitung, Radio etc.{" "}
                        <span className="fr">Nom du journal, radios…</span>
                      </label>
                    </div>
                  </div>
                  {press.map((row, i) => {
                    return (
                      <div className="row" key={i}>
                        <div className="cols">
                          <div>
                            <label>
                              {row.de} <span className="fr">{row.fr}</span>
                            </label>
                          </div>
                          <div>
                            <Input
                              name="revenue"
                              value={data.press[i] && data.press[i].col1}
                              onChange={(props) =>
                                onChangePress(i, "col1", props.value)
                              }
                            />
                          </div>
                          <div>
                            <Input
                              name="revenue"
                              value={data.press[i] && data.press[i].col2}
                              onChange={(props) =>
                                onChangePress(i, "col2", props.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}

            <h2 className="tall">
              Anlagen
              <br />
              <span className="fr">Pièces jointes</span>
            </h2>

            <p style={{ marginTop: "20px", marginBottom: "20px" }}>
              Folgende Anlagen müssen dem Antragsformular beigefügt werden.{" "}
              <span className="fr">
                Les documents suivants doivent être joints au dossier.
              </span>
            </p>
            <FileUpload
              labels={{
                de: "Kurzbeschreibung der antragstellenden Institution",
                fr: "Courte description de la structure porteuse du projet",
              }}
              type="file1"
              onChange={(props) => onChangeFile(props)}
              buttonLabels={{ de: "Hochladen", fr: "Télécharger" }}
              value={data.file1}
              errors={errors}
              isLoggedin={authentication.isLoggedin}
            />
            <FileUpload
              labels={{
                de: "Spielstättenbestätigung",
                fr: "Confirmation du / des lieu(x) de(s) manifestation(s)",
              }}
              type="file2"
              onChange={(props) => onChangeFile(props)}
              buttonLabels={{ de: "Hochladen", fr: "Télécharger" }}
              value={data.file2}
              errors={errors}
              isLoggedin={authentication.isLoggedin}
            />

            <FileUpload
              type="file7"
              onChange={(props) => onChangeFile(props)}
              buttonLabels={{ de: "Hochladen", fr: "Télécharger" }}
              value={data.file7}
              errors={errors}
              isLoggedin={authentication.isLoggedin}
            />

            <p style={{ marginTop: "40px" }}>
              Ausführliche Projektbeschreibung{" "}
              <span className="fr">Description détaillée du projet</span>
            </p>

            <p style={{ marginTop: "20px", marginBottom: "-10px" }}>
              Bitte fügen Sie eine ausführliche Projektbeschreibung hinzu in den
              konkreten Maßnahmen und Abläufe dargestellt werden. Bitte ergänzen
              Sie auch das konkrete Programm (gerne in tabellarischer Form).
              <br />
              <span className="fr">
                Veuillez nous envoyer une description détaillée du projet dans
                laquelle seront présentés les mesures concrètes et le
                déroulement du projet. Veuillez indiquer le programme détaillé
                (si vous le souhaitez, sous forme d'un tableau)
              </span>
            </p>

            <FileUpload
              labels={{
                de: "Aussagekräftiges Material kann, wenn nötig, zusätzlich als pdf hochgeladen werden (max. 5 MB). ",
                fr: "Nous acceptons aussi tout document ou support supplémentaire vous paraissant pertinent (max. 5 MB).",
              }}
              type="file3"
              onChange={(props) => onChangeFile(props)}
              buttonLabels={{ de: "Hochladen", fr: "Télécharger" }}
              value={data.file3}
              isLoggedin={authentication.isLoggedin}
            />

            <FileUpload
              type="file8"
              onChange={(props) => onChangeFile(props)}
              buttonLabels={{ de: "Hochladen", fr: "Télécharger" }}
              value={data.file8}
              errors={errors}
              isLoggedin={authentication.isLoggedin}
            />

            <Repeater
              name="links"
              value={data.links}
              onChange={(props) => onChange(props)}
              labels={{ de: "Link", fr: "Lien" }}
              errors={errors}
              removeLabels={{ de: "Entfernen", fr: "Supprimer" }}
              isLoggedin={authentication.isLoggedin}
              className={"closeAdd"}
            />
            <h2>
              Hinweise
              <br />
              <span className="fr">Remarques</span>
            </h2>
            <p>
              Impuls neue Musik kann eine finanzielle Unterstützung von bis zu
              50 % der Gesamtausgaben eines Projektes bewilligen. Nur ein
              vollständig ausgefüllter Antrag kann berücksichtigt werden. Ihre
              Angaben werden vertraulich behandelt und dienen ausschließlich den
              Förderungszwecken. Eine Haftung für verloren gegangene und
              beschädigte Unterlagen ist ausgeschlossen.
              <br />
              <span className="fr">
                Impuls neue Musik intervient en attribuant des aides financières
                qui sont plafonnées à 50 % des dépenses du projet. Seuls les
                dossiers complets seront pris en compte. Vos données
                personnelles resteront confidentielles et seront utilisées
                exclusivement à des fins promotionnelles. La responsabilité
                d’Impuls neue Musik ne pourra être engagée en cas de perte ou de
                dégradation des documents.
              </span>
            </p>
            <h2>
              Erklärungen
              <br />
              <span className="fr">Déclaration</span>
            </h2>
            <p>
              Ich versichere, dass meine Angaben vollständig sind und der
              Wahrheit entsprechen. Ich erkläre mein Einverständnis, dass meine
              personen- und projektbezogenen Daten elektronisch gespeichert und
              verarbeitet sowie an die Jury weitergereicht werden dürfen. Ich
              bin im Falle einer Förderung einverstanden mit der
              Veröffentlichung der relevanten Angaben.
              <br />
              <span className="fr">
                Je certifie que mes déclarations sont complètes et correctes. Je
                donne mon consentement pour que mes données personnelles et
                celles liées au projet soient stockées et traitées
                électroniquement ainsi que transmises au jury. En cas
                d’acceptation de mon dossier, je donne mon accord à Impuls neue
                Musik pour la publication d’informations concernant mon projet.
              </span>
            </p>
            <div className="cols3" style={{ marginTop: "100px" }}>
              <div>
                <Input
                  labels={{ de: "Ort", fr: "Lieu" }}
                  name="document_location"
                  value={data.document_location}
                  onChange={(props) => onChange(props)}
                  errors={errors}
                />
              </div>
              <div>
                <Datefield
                  labels={{ de: "Datum", fr: "Date" }}
                  name="document_date"
                  value={data.document_date}
                  onChange={(props) => onChange(props)}
                  errors={errors}
                />
              </div>
            </div>
            <div className="cols3">
              <div>
                <FileUpload
                  labels={{
                    de: "Unterschrift, Stempel",
                    fr: "signature, cachet du co-contractant",
                  }}
                  type="file6"
                  onChange={(props) => onChangeFile(props)}
                  buttonLabels={{
                    de: "Bild hochladen",
                    fr: "Télécharger le fichier",
                  }}
                  value={data.file6}
                  withPreview
                  errors={errors}
                  isLoggedin={authentication.isLoggedin}
                />
              </div>
            </div>
            {!readOnly && (
              <>
                {data.status === "publish" ? (
                  <>
                    <div className="state">
                      <h2 className="tall">
                        Der Förderantrag wurde eingereicht.
                        <br />
                        <span className="fr">
                          La demande de financement a été soumise.
                        </span>
                      </h2>
                      <span className="print" onClick={() => window.print()}>
                        Förderantrag drucken&nbsp;
                        <span className="fr">
                          Imprimer la demande de financement
                        </span>
                      </span>
                    </div>
                    {(data.step1_extended || data.step === 2) && (
                      <div
                        className="colsButtons"
                        style={{ marginTop: "30px" }}
                      >
                        <div className="button" onClick={handleSubmit}>
                          <div>
                            Fertigstellen und abschicken
                            <br />
                            <span className="fr">
                              Terminer et envoyer le dossier de candidature
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="colsButtons" style={{ marginTop: "100px" }}>
                    <div className="button" onClick={handleSave}>
                      <div>
                        Entwurf speichern
                        <br />
                        <span className="fr">sauvegarder le projet</span>
                      </div>
                    </div>
                    <div className="button" onClick={handleValidate}>
                      <div>
                        Antrag prüfen
                        <br />
                        <span className="fr">
                          vérifier le dossier de candidature
                        </span>
                      </div>
                    </div>
                    <div
                      className={"button submit " + submitButtonClass}
                      onClick={handleSubmit}
                    >
                      <div>
                        {data.status === "draft" ? (
                          <>
                            Fertigstellen und abschicken
                            <br />
                            <span className="fr">
                              Terminer et envoyer le dossier de candidature
                            </span>
                          </>
                        ) : (
                          <>
                            Der Förderantrag wurde eingereicht.
                            <br />
                            <span className="fr">
                              La demande de financement a été soumise.
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            {isPosting && (
              <div className="loading overlay">
                <div>
                  <p>Daten werden gespeichert...</p>
                </div>
              </div>
            )}
            {saved && (
              <div className="loading overlay">
                <div style={{ paddingTop: "80px" }}>
                  <p>
                    Die Daten wurden gespeichert. <br />
                    <span className="fr">
                      Les données ont été enregistrées.
                    </span>
                  </p>
                  <p>
                    <span
                      className="accept"
                      onClick={() => {
                        setSaved(false);
                      }}
                    >
                      OK
                    </span>
                  </p>
                </div>
              </div>
            )}
            {validated && (
              <div className="loading overlay">
                <div style={{ paddingTop: "80px" }}>
                  {errorHtml}
                  <p>
                    <span
                      className="accept"
                      onClick={() => {
                        setValidated(false);
                      }}
                    >
                      OK
                    </span>
                  </p>
                </div>
              </div>
            )}
            {finished && (
              <div className="loading overlay">
                <div style={{ paddingTop: "80px" }}>
                  <p>
                    Der Förderantrag wurde eingereicht.
                    <br />
                    <span className="fr">
                      La demande de financement a été soumise.
                    </span>
                  </p>
                  <p>
                    <span
                      className="accept"
                      onClick={() => {
                        setFinished(false);
                      }}
                    >
                      OK
                    </span>
                  </p>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const { authentication } = state;
  const applicationData = state.application.application;
  const isPosting = state.application.isPosting;

  console.log(applicationData);
  return {
    authentication,
    applicationData,
    isPosting,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getApplication: (id, key) => dispatch(getApplication(id, key)),
  postApplication: (data) => dispatch(postApplication(data)),
  logout: () => dispatch(logout()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Application)
);
