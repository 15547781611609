import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const Datefield = ({
  labels,
  name,
  value,
  onChange,
  errors,
  includeDateIntervals,
  openToDate,
}) => {
  const handleChange = (date) => {
    const newDate = date ? moment(date).format("DD/MM/YYYY") : "";

    onChange({
      name,
      value: newDate,
    });
  };

  //console.log( moment(value).format('DD/MM/YYYY') )

  let classes = [];
  if (errors && errors.findIndex((error) => error.name === name) > -1)
    classes.push("error");

  return (
    <div className={classes.join(" ")}>
      <label>
        {labels.de} <span className="fr">{labels.fr}</span>
      </label>
      <DatePicker
        selected={value ? moment(value, "DD/MM/YYYY").toDate() : null}
        onChange={handleChange}
        dateFormat="dd.MM.yyyy"
        includeDateIntervals={includeDateIntervals}
        openToDate={openToDate}
      />
    </div>
  );
};

export default Datefield;
