import React from "react";

import Input from "./Input";
import Datefield from "./Datefield";
import Select from "./Select";

const Row = ({
  type,
  data,
  onChange,
  errors,
  labels,
  index,
  onRemove,
  isLoggedin,
  includeDateIntervals,
  openToDate,
}) => {
  const handleChange = (props) => {
    onChange({ ...data, [props.name]: props.value });
  };

  //console.log('rowErrors:')
  //console.log(errors)

  let html;

  switch (type) {
    case "projektpartners":
      html = (
        <div className="cols3">
          <div>
            <Input
              labels={{ de: "Name", fr: "Nom de la structure" }}
              name="name"
              value={data.name}
              onChange={(props) => handleChange(props)}
              errors={errors}
            />
          </div>
          <div>
            <Input
              labels={{ de: "Ansprechpartner:in", fr: "Personne à contacter" }}
              name="contact"
              value={data.contact}
              onChange={(props) => handleChange(props)}
              errors={errors}
            />
          </div>
          <div>
            <Select
              labels={{ de: "Land", fr: "Pays" }}
              name="country"
              value={data.country}
              options={[
                {
                  value: "Deutschland",
                  labels: { de: "Deutschland", fr: "Allemagne" },
                },
                { value: "Schweiz", labels: { de: "Schweiz", fr: "Suisse" } },
                {
                  value: "Frankreich",
                  labels: { de: "Frankreich", fr: "France" },
                },
                {
                  value: "Luxemburg",
                  labels: { de: "Luxemburg", fr: "Luxembourg" },
                },
                { value: "Anderes", labels: { de: "Anderes", fr: "Autre" } },
              ]}
              onChange={(props) => {
                console.log(props);
                handleChange(props);
              }}
              errors={errors}
              custom={false}
            />
          </div>
          <div>
            <Input
              labels={{ de: "Tel. Nr.", fr: "N° de téléphone" }}
              name="phone"
              value={data.phone}
              onChange={(props) => handleChange(props)}
              errors={errors}
              addClasses={["telephone"]}
              type="number"
            />
          </div>
          <div>
            <Input
              labels={{ de: "E-Mail" }}
              name="email"
              value={data.email}
              onChange={(props) => handleChange(props)}
              errors={errors}
            />
          </div>
        </div>
      );

      break;

    case "dates":
      html = (
        <div className="cols3">
          <div>
            <Input
              labels={{ de: "Land", fr: "Pays" }}
              name="country"
              value={data.country}
              onChange={(props) => handleChange(props)}
              errors={errors}
            />
          </div>
          <div>
            <Input
              labels={{ de: "Stadt", fr: "Ville" }}
              name="city"
              value={data.city}
              onChange={(props) => handleChange(props)}
              errors={errors}
            />
          </div>
          <div>
            <Input
              labels={{ de: "Veranstaltungsort(e)", fr: "Lieu(x)" }}
              name="location"
              value={data.location}
              onChange={(props) => handleChange(props)}
              errors={errors}
            />
          </div>
          <div>
            <Datefield
              labels={{ de: "Anfangsdatum", fr: "Date de début" }}
              name="date_start"
              value={data.date_start}
              onChange={(props) => handleChange(props)}
              errors={errors}
              includeDateIntervals={includeDateIntervals}
              openToDate={openToDate}
            />
          </div>
          <div>
            <Datefield
              labels={{ de: "Enddatum", fr: "Date de fin" }}
              name="date_end"
              value={data.date_end}
              onChange={(props) => handleChange(props)}
              errors={errors}
              includeDateIntervals={includeDateIntervals}
              openToDate={openToDate}
            />
          </div>
        </div>
      );

      break;

    case "projectparticipants":
      html = (
        <div className="cols3">
          <div>
            <Input
              labels={{ de: "Vorname", fr: "Prénom" }}
              name="firstname"
              value={data.firstname}
              onChange={(props) => handleChange(props)}
              errors={errors}
            />
          </div>
          <div>
            <Input
              labels={{ de: "Name", fr: "Nom" }}
              name="name"
              value={data.name}
              onChange={(props) => handleChange(props)}
              errors={errors}
            />
          </div>
          <div>
            <Input
              labels={{ de: "E-Mail" }}
              name="email"
              value={data.email}
              onChange={(props) => handleChange(props)}
              errors={errors}
            />
          </div>
          <div>
            <Input
              labels={{ de: "Name des Ensembles", fr: "Nom de l’ensemble" }}
              name="ensemblename"
              value={data.ensemblename}
              onChange={(props) => handleChange(props)}
              errors={errors}
            />
          </div>
          <div>
            <Input
              labels={{ de: "Anzahl der Personen", fr: "Nombre de personnes" }}
              name="personcount"
              value={data.personcount}
              onChange={(props) => handleChange(props)}
              errors={errors}
            />
          </div>
          <div>
            <Input
              labels={{ de: "Nationalität", fr: "Nationalité" }}
              name="nationality"
              value={data.nationality}
              onChange={(props) => handleChange(props)}
              errors={errors}
            />
          </div>
          <div>
            <Input
              labels={{ de: "Land", fr: "Pays de résidence" }}
              name="country"
              value={data.country}
              onChange={(props) => handleChange(props)}
              errors={errors}
            />
          </div>
          <div>
            <Datefield
              labels={{ de: "Anreise", fr: "Date d'arrivée" }}
              name="date_start"
              value={data.date_start}
              onChange={(props) => handleChange(props)}
              errors={errors}
            />
          </div>
          <div>
            <Datefield
              labels={{ de: "Abreise", fr: "Date de départ" }}
              name="date_end"
              value={data.date_end}
              onChange={(props) => handleChange(props)}
              errors={errors}
            />
          </div>
        </div>
      );

      break;

    case "audience":
      html = (
        <div className="cols3">
          <div>
            <Input
              labels={{ de: "Veranstaltungsort", fr: "Lieu" }}
              name="city"
              value={data.city}
              onChange={(props) => handleChange(props)}
              errors={errors}
            />
          </div>
          <div>
            <Input
              labels={{
                de: "Anzahl der Veranstaltungen",
                fr: "Nombre de représentations",
              }}
              name="datescount"
              value={data.datescount}
              onChange={(props) => handleChange(props)}
              errors={errors}
            />
          </div>
          <div>
            <Input
              labels={{ de: "Saalkapazität", fr: "Capacité du lieu" }}
              name="hallcapacity"
              value={data.hallcapacity}
              onChange={(props) => handleChange(props)}
              errors={errors}
            />
          </div>
          <div>
            <Input
              labels={{ de: "Auslastung", fr: "Taux de remplissage" }}
              name="workload"
              value={data.workload}
              onChange={(props) => handleChange(props)}
              errors={errors}
            />
          </div>
          <div>
            <Input
              labels={{
                de: "Verkaufte Tickets",
                fr: "Nombre d’entrées payantes",
              }}
              name="ticketssold"
              value={data.ticketssold}
              onChange={(props) => handleChange(props)}
              errors={errors}
            />
          </div>
        </div>
      );

      break;

    case "composers":
      html = (
        <div className="cols3">
          <div>
            <Input
              labels={{ de: "Vorname", fr: "Prénom" }}
              name="firstname"
              value={data.firstname}
              onChange={(props) => handleChange(props)}
              errors={errors}
            />
          </div>
          <div>
            <Input
              labels={{ de: "Name", fr: "Nom" }}
              name="name"
              value={data.name}
              onChange={(props) => handleChange(props)}
              errors={errors}
            />
          </div>
          <div>
            <Input
              labels={{ de: "Werk(e)", fr: "Œuvre(s)" }}
              name="work"
              value={data.work}
              onChange={(props) => handleChange(props)}
              errors={errors}
            />
          </div>
          <div>
            <Select
              labels={{ de: "Land", fr: "Pays" }}
              name="country"
              value={data.country}
              options={[
                {
                  value: "Deutschland",
                  labels: { de: "Deutschland", fr: "Allemagne" },
                },
                { value: "Schweiz", labels: { de: "Schweiz", fr: "Suisse" } },
                {
                  value: "Frankreich",
                  labels: { de: "Frankreich", fr: "France" },
                },
                {
                  value: "Luxemburg",
                  labels: { de: "Luxemburg", fr: "Luxembourg" },
                },
                { value: "Anderes", labels: { de: "Anderes", fr: "Autre" } },
              ]}
              onChange={(props) => handleChange(props)}
              errors={errors}
              custom={false}
            />
          </div>
        </div>
      );

      break;

    case "ensembles":
      html = (
        <div className="cols3">
          <div className="double">
            <Input
              labels={{ de: "Name", fr: "Nom" }}
              name="name"
              value={data.name}
              onChange={(props) => handleChange(props)}
              errors={errors}
            />
          </div>
          <div>
            <Select
              labels={{ de: "Land", fr: "Pays" }}
              name="country"
              value={data.country}
              options={[
                {
                  value: "Deutschland",
                  labels: { de: "Deutschland", fr: "Allemagne" },
                },
                { value: "Schweiz", labels: { de: "Schweiz", fr: "Suisse" } },
                {
                  value: "Frankreich",
                  labels: { de: "Frankreich", fr: "France" },
                },
                {
                  value: "Luxemburg",
                  labels: { de: "Luxemburg", fr: "Luxembourg" },
                },
                { value: "Anderes", labels: { de: "Anderes", fr: "Autre" } },
              ]}
              onChange={(props) => handleChange(props)}
              errors={errors}
            />
          </div>
        </div>
      );

      break;

    case "links":
      html = (
        <div className="cols3">
          <div className="double">
            <Input
              labels={{
                de: "Video- und/oder Audio-Links",
                fr: "Liens audio et/ou vidéo ",
              }}
              name="link"
              value={data.link}
              onChange={(props) => handleChange(props)}
              errors={errors}
            />
          </div>
        </div>
      );

      break;
  }

  return (
    <div>
      {html}
      {index > 0 && isLoggedin && (
        <span className="remove" onClick={() => onRemove(index)}>
          {labels.de} <span className="fr">{labels.fr}</span>
        </span>
      )}
    </div>
  );
};

const Repeater = ({
  labels,
  name,
  value,
  onChange,
  errors,
  removeLabels,
  isLoggedin,
  className,
  includeDateIntervals,
  openToDate,
}) => {
  const empty = {
    name: "",
    contact: "",
    address: "",
    phone: "",
    email: "",
  };

  const handleChange = (i, data) => {
    const valueNew = value;
    valueNew[i] = data;

    onChange({ name, value: valueNew });
  };

  const addRow = () => {
    let data = value.length ? value : [{}];
    data.push({});

    onChange({ name, value: data });
  };

  const removeRow = (index) => {
    let data = value;
    data.splice(index, 1);

    onChange({ name, value: data });
  };

  let data = value.length > 0 ? value : [{}];

  let repeaterErrors;

  if (errors && errors.length) {
    repeaterErrors = errors.find((error) => error.name === name);

    if (repeaterErrors) repeaterErrors = repeaterErrors.errors;
  }

  //console.log('repeaterErrors')
  //console.log(repeaterErrors)

  let classes = ["repeater"];
  if (className) classes.push(className);

  return (
    <div className={classes.join(" ")}>
      {data.map((row, i) => {
        const rowErrors =
          repeaterErrors && repeaterErrors[i] ? repeaterErrors[i] : [];
        //console.log('>>>')
        //console.log(rowErrors)

        return (
          <Row
            key={i}
            type={name}
            data={row}
            onChange={(data) => handleChange(i, data)}
            errors={rowErrors}
            index={i}
            labels={removeLabels}
            onRemove={(index) => removeRow(index)}
            isLoggedin={isLoggedin}
            includeDateIntervals={includeDateIntervals}
            openToDate={openToDate}
          />
        );
      })}
      {isLoggedin && (
        <span className="add" onClick={() => addRow()}>
          {labels.de} <span className="fr">{labels.fr}</span>
        </span>
      )}
      {name === "composers" && (
        <div style={{ marginTop: "20px", marginBottom: "55px" }}>
          <p>
            * Bei der Kategorie "Land" ist der Lebens- und Arbeitsmittelpunkt
            gemeint, nicht zwingend die Nationalität.
            <br />
            <span className="fr">
              * La catégorie "pays" se réfère au pays de résidence et de
              travail, et non nécessairement à la nationalité.
            </span>
          </p>
        </div>
      )}
    </div>
  );
};

export default Repeater;
